import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";

function TiTusindeSkridt() {
  return (
    <>
      <SEO
        title="Sådan kommer du nemmere op på de 10.000 skridt i dagligdagen"
        description="Se hvilke værktøjer vi anbefaler, for at komme nemmere op på de 10.000 skridt i løbet af arbejdsdagen"
      />
      <article className="flex flex-col items-center mx-auto max-w-4xl my-12">
        <h1 className="font-bold text-4xl my-4">
          Kom nemmere op på de 10.000 skridt
        </h1>
        <div style={{ height: 330 }} className="w-full mb-8">
          <img
            className="w-full h-full object-cover"
            src="https://images.pexels.com/photos/6455836/pexels-photo-6455836.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Odin R1 Walking Pad"
          />
        </div>
        <div className="text-xl">
          <p className="mb-4">
            Under coronakrisen, fandt vi ud af at vi faktisk godt kan finde ud
            af at arbejde hjemmefra. Og det er helt sikkert noget, der er kommet
            for at blive, viser det sig.
          </p>
          <p className="mb-4">
            Dette giver en mulighed vi her vil skrive en artikel om. Det er
            nemlig noget vi også ser blive mere og mere populært i hjemmet,
            blandt folk der jævnligt har en hjemmearbejdsdag, som sidder på
            computeren og arbejder i løbet af dagen.
          </p>
          <p className="mb-4">
            Man kan nemlig slå to fluer med et smæk. <br />
            Med et walking pad, er det markant nemmere, at runde de 10.000
            skridt - uden egentligt at yde den store indsats. Du tvinger ikke
            dig selv udenfor, på en våd mandag. Nej, du kan nemlig med lethed
            folde det ud under et hævet skrivebord, og fortsætte med at være
            fokuseret på arbejdet.
          </p>
          <p className="mb-4">
            Vi hører fra flere og flere danskere, at de ved gå 1-2 timer mens de
            arbejder, pludselig har gået kilometer på en walking pad, uden
            overhovedet at have bemærket det.
          </p>
          <h2 className="mb-4">
            Vi anbefaler <b>Odin R1 Walking Pad</b>
          </h2>
          Et{" "}
          <Link className="underline" to="/andet/odin-r1-walking-pad">
            Odin R1 Walking Pad
          </Link>{" "}
          er vores anbefaling. Det er en walking pad med kvalitet og indbygget
          motor, så du ikke selv skal gå den i gang. Derudover kan den komme op
          på 10 km/t, hvilket endda giver dig mulighed for at løbe en tur på
          det, hvis du skulle få lyst til det også. Samtidig er en af de største
          fordele ved en Odin R1 Walking Pad at den er foldbar, og ikke fylder
          stuen/kontoret op med at løbebånd. På den måde kan du finde den frem
          under arbejdets gåtur og gemme den væk i skabet igen når dagen er
          omme.
        </div>
      </article>
    </>
  );
}

export default TiTusindeSkridt;
